<template>
    <div>

        <div class="d-flex justify-content-end mb-2">
            <select class="custom-select ml-1" style="width: 150px;" :value="results" @input="changeResults" data-cy="resultados-pagina" >
                <option value="10">10 por página</option>
                <option value="20">20 por página</option>
                <option value="50">50 por página</option>
                <option value="100">100 por página</option>
            </select>
        </div>
        <div>
            <DataTable async nosearch scroll noedit condensed :loading="loading" :colunas="parsedCols" :linhas="parsedLinhas" :errMsg="errMsg" :state="state" @state-change="setStateDataTable">
                <template #Área="{ value }">
                    <AreaHierarquica :nome="value[0]" :id="value[1]" />
                </template>
                 <template #Reativar="{ value }">
                    <button class="btn btn-success btn-sm px-3" @click="confirmaReativacao(value[0], value[1], value[2], value[3])"> Reativar </button>
                </template>
            </DataTable>

        </div>
        <Paginator :page="page" :pages="pages" :count="count" :disabled="loading" :first="first" :last="last" @paginate="paginate" />
    </div>
</template>
<script>
import DataTable from '@/components/DataTable'
import axios from 'axios'
import api from '@/api'
import Paginator from '@/components/Paginator'
import AreaHierarquica from '@/components/AreaHierarquica'
import dayjs from 'dayjs'

function options (timeout) {
    return {
        timeout: timeout || 2000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
    }
}

export default {
    components: {
        DataTable,
        Paginator,
        AreaHierarquica,

    },
    props: {
        filtros: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            lista: [],
            colsName: ['id' , null, 'classeAlarme', 'nomeArea', 'nomeDispositivo', 'dataHoraInicial', null, null],
            cols: ['#', 'Tipo de dispositivo', 'Modo de manutenção', 'Área', 'Nome', 'Dada de ínicio da manutenção', 'Usuário', 'Reativar'],
            page: 0,
            pages: 1,
            results: 20,
            first: 0,
            last: 0,
            count: 0,
            loading: false,
            errMsg: '',
            retracted: true,
            dataInicial: null,
            dataFinal: null,

            state: {
                sortBy: 0,
                sortAsc: false,
                query: ''
            }
        }
    },
    computed: {
        parsedCols() {
            return this.cols.map((c, idx) => this.colsName[idx] ? c : { value: c, sortable: false });
        },
        parsedLinhas() {
            return this.lista.map(row => ({
                ...row,
            }));
        },
    },
    methods: {
        setStateDataTable (state) {
            this.state.sortBy = state.sortBy;
            this.state.sortAsc = state.sortAsc;
            this.page = 0;
            this.update(1, this.results);
        },
        changeResults (event) {
            this.page = 0;
            this.update(1, event.target.value);
        },
        paginate (p) {
            if (p === this.page) return;
            this.page = p;
            this.update(this.page, this.results);
        },

        reativaDispositivo(id, classeAlarme, tipoDispositivo, dataInicioCalibracao, dataFimCalibracao, idDispositivo) {
            this.$snotify.async('Aguarde...', () => new Promise((resolve, reject) => {
                axios.put(api.v1.manutencao.reactivate, { id }).then(() => {
                    if(classeAlarme === 'Calibração') {
                        if(tipoDispositivo === 'Concentrador') {
                            axios.put(api.v1.concentrador.updateCalibracao, { id: idDispositivo, dataInicioCalibracao, dataFimCalibracao }).then(() => {
                                resolve({
                                    title: 'Sucesso!',
                                    body: 'Dispositivo reativado',
                                    config: options()
                                });
                                this.update();
                            });
                        } else if(tipoDispositivo === 'Detector') {
                             axios.put(api.v1.detector.updateCalibracao, { id: idDispositivo, dataInicioCalibracao, dataFimCalibracao }).then(() => {
                                resolve({
                                    title: 'Sucesso!',
                                    body: 'Dispositivo reativado',
                                    config: options()
                                });
                                this.update();
                            });
                        }
                    } else {
                        resolve({
                            title: 'Sucesso!',
                            body: 'Dispositivo reativado',
                            config: options()
                        });
                        this.update();
                    }
                }).catch(e => {
                    let error = e.response.data ? e.response.data.error : e.toString();
                    reject({
                        title: 'Erro!',
                        body: error,
                        config: options(10000)
                    })
                });
            }));
        },

        confirmaReativacao(id, classeAlarme, tipoDispositivo, idDispositivo) {
            let hoje = dayjs().format('YYYY-MM-DD');
            this.$swal.fire({
                title: 'Você tem certeza?',
                html:
                `<p>Ao realizar esta ação as notificações de geração de alertas e alarmes serão
                reestabelecidas no sistema.
                ${classeAlarme === 'Calibração' ?` Insira um novo período de calibração para continuar.</p>
                <div class="d-flex justify-content-around">
                <div class="form-group p-1 ml-1">
                <label for="data_inicio">Data inicial *</label>
                <input mim="${hoje}" class="form-control" type="date" id="data_inicio">
                </div>
                <div class="form-group p-1 mr-1">
                <label for="data_fim">Data final *</label>
                <input mim="${hoje}" class="form-control" type="date" id="data_fim">
                </div>
                </div>
                <p class="small text-right mt-2 mb-0">Campos marcados com * são obrigatórios.</p>` : '</p>'}`,
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                focusCancel: true,
                confirmButtonColor: '#2e7d32',
                confirmButtonText: `Confirmar`,
                cancelButtonText: 'Cancelar',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !this.$swal.isLoading(),
                preConfirm: async () => {
                    if(classeAlarme === 'Calibração') {
                        this.dataInicial = document.getElementById('data_inicio').value;
                        this.dataFinal = document.getElementById('data_fim').value;

                        if(!this.dataInicial || !this.dataFinal) {
                            return this.$swal.showValidationMessage('Preencha todos os campos obrigatórios.');
                        }
                        if(new Date(this.dataInicial) <= new Date()) {
                            return this.$swal.showValidationMessage('A data inicial deve ser maior que a data de hoje.');
                        }
                        if(this.dataInicial > this.dataFinal) {
                            return this.$swal.showValidationMessage('A data final não pode ser menor que a data inicial.');
                        }
                    }

                }
            }).then(res => {
                if(res.value){
                    this.reativaDispositivo(id, classeAlarme, tipoDispositivo, this.dataInicial, this.dataFinal, idDispositivo);
                }
            });
        },

        update(page = 1, results = 20) {
            this.loading = true;
            let url = api.v1.manutencao.list(page, results, JSON.stringify(this.filtros), this.colsName[this.state.sortBy], this.state.sortAsc);
            return axios.get(url).then(res => {
                if (res.data.rows) { // paginação ativada
                    this.page = res.data.page;
                    this.pages = res.data.pages;
                    this.first = res.data.first;
                    this.last = res.data.last;
                    this.count = res.data.count;
                    this.results = res.data.results;
                }
                this.lista = (res.data.rows || res.data).map(i => {
                    let inicio = new Date(i.dataHoraInicial);
                    let tipoDispositivo = i.idConversor ? 'Conversor' : (i.idConcentrador ? 'Concentrador' : 'Detector');
                    let idDispositivo = i.idConversor ? i.idConversor : (i.idConcentrador ? i.idConcentrador : i.idDetector);
                    return {
                        cols: [i.id, tipoDispositivo, i.classeAlarme, [i.nomeArea, i.idArea], i.nomeDispositivo, inicio.toLocaleString(), i.manutencao[0]?.usuario.nome, [i.id, i.classeAlarme, tipoDispositivo, idDispositivo]]
                    }
                });
                this.errMsg = '';
            }).catch(reason => {
                this.lista = [];
                this.errMsg = reason?.response?.data ?? reason.toString();
            }).then(() => {
                this.loading = false;
            });
        },
    },
    watch: {
        filtros() {
            this.page = 0;
            this.update();
        },
    },
    created () {
        this.update();
    }
}
</script>
