<template>
    <two-cards>
        <template #header>
            <h4 class="card-title">Dispositivos em manutenção</h4>
            <button v-if="retracted" class="btn btn-success" @click="toggle">Adicionar</button>
            <button v-else class="btn btn-secondary" @click="toggle">Fechar</button>

        </template>
        <template #one>
            <div>
                <vue-slide-up-down :active="!retracted">
                    <h2>Adicionar</h2>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="i_nome">Conversores</label>
                            <vue-multi-select class="multi-100 mb-2" :disabled="loading" v-model="conversores" search historyButton :filters="multiSelectFilters[1]" :options="multiSelectOptions" :selectOptions="conversorOptions" @input="updateDevices" data-cy="Concentradores" :btnLabel="() => customLabel(conversores)"/>
                            <button class="btn btn-warning w-100" :disabled="!conversores.length" @click="confirmaManutencao(conversores, 'Manutenção', 'Conversores')">Colocar em manutencao</button>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="i_nome">Concentradores</label>
                            <vue-multi-select class="multi-100 mb-2" :disabled="loading" v-model="concentradores" search historyButton :filters="multiSelectFilters[2]" :options="multiSelectOptions" :selectOptions="concentradorOptions" @input="getDetectores" data-cy="Concentradores" :btnLabel="() => customLabel(concentradores)"/>
                            <div class="d-flex">
                                <button class="btn btn-warning mr-1 flex-grow-1" :disabled="!concentradores.length" @click="confirmaManutencao(concentradores, 'Manutenção', 'Concentradores')">Colocar em manutenção</button>
                                <button class="btn btn-warning flex-grow-1" :disabled="!concentradores.length" @click="confirmaManutencao(concentradores, 'Calibração', 'Concentradores')">Colocar em calibração</button>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="i_nome">Detectores</label>
                            <vue-multi-select class="multi-100 mb-2" :disabled="loading" v-model="detectores" search historyButton :filters="multiSelectFilters[3]" :options="multiSelectOptions" :selectOptions="detectorOptions" data-cy="Detectores" :btnLabel="() => customLabel(detectores)"/>
                            <div class="d-flex">
                                <button class="btn btn-warning mr-1 flex-grow-1" :disabled="!detectores.length" @click="confirmaManutencao(detectores, 'Manutenção', 'Detectores')">Colocar em manutenção</button>
                                <button class="btn btn-warning flex-grow-1" :disabled="!detectores.length" @click="confirmaManutencao(detectores, 'Calibração', 'Detectores')">Colocar em calibração</button>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-2">
                </vue-slide-up-down>

                <form @submit.prevent="pesquisar">
                    <h2 class="mb-4">Filtros</h2>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="i_nome">Tipo do dispositivo</label>
                            <vue-multi-select class="multi-100 mb-2"  v-model="tiposDispositivo" search historyButton :filters="multiSelectFilters[4]" :options="multiSelectOptions" :selectOptions="tiposDispositivosOptions" @input="getDispositivos" data-cy="Tipo de dispositivo" :btnLabel="() => customLabel(tiposDispositivo)"/>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="i_nome">Dispositivo</label>
                            <vue-multi-select class="multi-100 mb-2" :disabled="!tiposDispositivo.length" v-model="dispositivos" search historyButton :filters="multiSelectFilters[5]" :options="multiSelectOptions" :selectOptions="dispositivosOptions" data-cy="Dispositivos" :btnLabel="() => customLabel(dispositivos)"/>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="i_nome">Modo de manutenção</label>
                            <vue-multi-select class="multi-100 mb-2" v-model="modoManutencao" search historyButton :filters="multiSelectFilters[6]" :options="multiSelectOptions" :selectOptions="modoManutencaoOptions" data-cy="Modo de manutenção" :btnLabel="() => customLabel(modoManutencao)"/>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between tcs-card-footer-action">
                        <button class="btn btn-link ml-3" type="button" data-cy="Limpar" @click="limparFiltros">Limpar filtros</button>
                        <button class="btn btn-success tcs-btn" type="submit" data-cy="Pesquisar"><SearchIcon /></button>
                    </div>
                </form>
            </div>
        </template>
        <template #two>
                <manutencao-list ref="tabela" :filtros="filtros"/>
        </template>
    </two-cards>
</template>

<script>
import TwoCards from "../templates/TwoCards"
import axios from 'axios'
import api from '@/api.js'

import VueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

import VueSlideUpDown from 'vue-slide-up-down'
import ManutencaoList from './Manutencao/List'

const filters = () => [{
    nameAll: 'Selecionar todas',
    nameNotAll: 'Deselecionar todas',
    func() {
        return true;
    },
}];

function options (timeout) {
    return {
        timeout: timeout || 2000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
    }
}

export default {
    components: {
        TwoCards,
        VueMultiSelect,
        ManutencaoList,
        VueSlideUpDown,
    },
    data() {
        return {
            retracted: true,
            detectores: [],
            concentradores: [],
            conversores: [],
            tiposDispositivo: [],
            dispositivos: [],
            modoManutencao: [],

            detectorOptions: [],
            concentradorOptions: [],
            conversorOptions: [],
            tiposDispositivosOptions: [{ id: 0, name: 'Conversor' }, { id:1, name: 'Concentrador' }, {id: 2, name: 'Detector' }],
            dispositivosOptions: [],
            modoManutencaoOptions: [{ id: 3, name: 'Calibração' }, { id: 4, name: 'Manutenção' }],

            multiSelectFilters: Array(8).fill(0).map(() => filters()),
            multiSelectOptions: {
                multi: true,
            },

            filtros: {},
            errMsg: '',
            loading: true,
        }
    },
    mounted() {
        this.getConversores();
        this.getConcentradores();
        this.getDetectores();
    },
    methods: {
        customLabel(selected) {
            return selected.length ? `${selected.length} selecionado${selected.length > 1 ? 's' : ''}` : 'Selecione';
        },
        toggle() {
            this.retracted = !this.retracted;
        },
        getConversores() {
            //reseta campo
            this.conversores = [];

            this.loading = true;
            return axios.get(api.v1.conversor.list(1,-1)).then(res => {
                this.conversorOptions = res.data.rows.map(el => ({ name: el.nome, id: el.id }));
                this.loading = false;
            });
        },
        getConcentradores() {
            //reseta campo
            this.concentradores = [];

            this.loading = true;
            return axios.get(api.v1.concentrador.list(1,-1)).then(res => {
                this.concentradorOptions = res.data.rows.map(el => ({ name: el.nome, id: el.id }));
                this.loading = false;

            });
        },
        getDetectores() {
            //reseta campo
            this.detectores = [];

            this.loading = true;
            return axios.get(api.v1.detector.list(1,-1)).then(res => {
                this.detectorOptions = res.data.rows.map(el => ({ name: el.nome, id: el.id }));
                this.loading = false;
            });
        },

        getDispositivos() {
            //reseta campo
            this.dispositivos = [];
            this.dispositivosOptions = [];

            this.loading = true;
            if (this.tiposDispositivo.length > 0 && this.tiposDispositivo.find(el => el.name === 'Conversor')) {
                axios.get(api.v1.conversor.list(1,-1)).then(res => {
                    this.dispositivosOptions.push(...res.data.rows.map(el => ({ name: el.nome, id: el.id })));
                    this.loading = false;
                });
            } if (this.tiposDispositivo.length > 0 && this.tiposDispositivo.find(el => el.name ===  'Concentrador')) {
                axios.get(api.v1.concentrador.list(1,-1)).then(res => {
                    this.dispositivosOptions.push(...res.data.rows.map(el => ({ name: el.nome, id: el.id })));
                    this.loading = false;
                });
            } if(this.tiposDispositivo.length > 0 && this.tiposDispositivo.find(el => el.name ===  'Detector')) {
                axios.get(api.v1.detector.list(1,-1)).then(res => {
                    this.dispositivosOptions.push(...res.data.rows.map(el => ({ name: el.nome, id: el.id })));
                    this.loading = false;
                });
            }

        },
        async updateDevices() {
            await this.getConcentradores();
            await this.getDetectores();
        },

        limparFiltros() {
            this.modoManutencao = [];
            this.tiposDispositivo = [];
            this.dispositivos = [];
        },

        ColocarEmManutencao(dispositivos, classeAlarme, tipoDispositivo) {
            this.$snotify.async('Aguarde...', () => new Promise((resolve, reject) => {
                let payload = {};
                payload.dispositivos = dispositivos.map(d => ({
                    idConversor: this.conversores.some(el => el.nome === d.nome) && tipoDispositivo === 'Conversores' ? this.conversores.find(el => el.nome === d.nome).id : null,
                    idConcentrador: this.concentradores.some(el => el.nome === d.nome) && tipoDispositivo === 'Concentradores' ? this.concentradores.find(el => el.nome === d.nome).id : null,
                    idDetector: this.detectores.some(el => el.nome === d.nome) && tipoDispositivo === 'Detectores' ? this.detectores.find(el => el.nome === d.nome).id : null,
                    classeAlarme: classeAlarme,
                    dataHoraInicial: new Date(),
                }));
                axios.post(api.v1.manutencao.create, payload).then(() => {
                    resolve({
                        title: 'Sucesso!',
                        body: `Dispositivos colocados em ${classeAlarme.toLowerCase()}`,
                        config: options()
                    });
                    this.$refs.tabela.update();
                    this.detectores = [];
                    this.concentradores = [];
                    this.conversores = [];
                }).catch(e => {
                    let error = e.response.data ? e.response.data.error : e.toString();
                    reject({
                        title: 'Erro!',
                        body: error,
                        config: options(10000)
                    })
                });
            }));
        },

        confirmaManutencao(dispositivos, classeAlarme, tipoDispositivo) {
            this.$swal.fire({
                title: 'Você tem certeza?',
                text: `Deseja colocar todos os dispositivos selecionados em ${classeAlarme.toLowerCase()}?
                Ao realizar essa ação alarmes e alertas deixarão de ser gerados no sistema para estes dispositivos.`,
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                focusCancel: true,
                confirmButtonColor: '#dc3545',
                confirmButtonText: `Colocar em ${classeAlarme.toLowerCase()}`,
                cancelButtonText: 'Cancelar',
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then(res => {
                if (res.value) {
                    this.ColocarEmManutencao(dispositivos, classeAlarme, tipoDispositivo);
                }
            })
        },
        pesquisar() {
            let filtros = {};
            let idConversor;
            let idConcentrador;
            let idDetector;

            if(this.dispositivos.length > 0) {
                let nomes = this.dispositivos.map(el => el.name);

                let convIdOptions = this.conversorOptions.filter(el => nomes.some(elm => elm === el.name));
                if(convIdOptions.length > 0) idConversor = convIdOptions.map(el => el.id);

                let concIdOptions = this.concentradorOptions.filter(el => nomes.some(elm => elm === el.name));
                if(concIdOptions.length > 0) idConcentrador = concIdOptions.map(el => el.id);

                let detIdOptions = this.detectorOptions.filter(el => nomes.some(elm => elm === el.name));
                if(detIdOptions.length > 0) idDetector = detIdOptions.map(el => el.id);

            } else if(this.tiposDispositivo.length > 0) {
                if (this.tiposDispositivo.some(el => el.name === 'Conversor'))
                    idConversor = this.conversorOptions.map(el => el.id);

                if (this.tiposDispositivo.some(el => el.name === 'Concentrador'))
                    idConcentrador = this.concentradorOptions.map(el => el.id);

                if (this.tiposDispositivo.some(el => el.name === 'Detector'))
                    idDetector = this.detectorOptions.map(el => el.id);
            }

            filtros = {
                idConversor,
                idConcentrador,
                idDetector,
            }

            if (this.modoManutencao.length > 0) {
                filtros.classeAlarme = this.modoManutencao.map(el => el.name);

            }
            this.filtros = filtros;
        }
    },
}
</script>
<style scoped>
    label {
        display: block;
    }
    @media (max-width: 790px) {
        .btn.btn-success {
            margin-top: 12px;
        }
    }
</style>
